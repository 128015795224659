.song-container {
  min-height: 60vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .blur{
    position: absolute;
    animation: rotation 10s infinite linear;
    width: 30%;
    border-radius: 50%;
    display: inline-block;
    filter: blur(80px);
    z-index: -5;
  }
  img{
    animation: rotation 10s infinite linear;
    width: 23%;
    border-radius: 50%;
  }
  h2 {
    padding: 3rem 1rem 1rem 1rem;
  }
  h3 {
    font-size: 1rem;
  }
  // .dot {
  //   position:absolute;
  //   height: 250px;
  //   width: 250px;
  //   background-color: rgb(0, 0, 0);
  //   border-radius: 50%;
  //   justify-content: center;
  //   display: inline-block;
    
  //   transform: translate(0px,-50px);
    
  // }
}
@media screen and (max-width: 768px) {
  .song-container {
    img {
      width: 60%;
    }
    .blur{
      width: 50%;
      filter: blur(30px);
    }
  }
}
@media screen and (max-width: 768px) {
  .time-control {
    width: 90%;
  }
  .play-control {
    width: 60%;
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
